import http from "@/api/http.js";

function listUmsOrganization(sysAppMenuName) {
	return http({
		url: "/UmsUserPermission/ListUmsOrganizationBySysAppMenuName/" + sysAppMenuName,
		method: "get"
	});
}

function listByUserId(userId) {
	return http({
		url: "/UmsUserPermission/ListByUmsUserId/" + userId,
		method: "get"
	});
}

function create(payload) {
	return http({
		url: "/UmsUserPermission/Create",
		method: "post",
		data: {
			userId: payload.userId,
			sysAppId: payload.sysAppId,
			sysAppMenuId: payload.sysAppMenuId,
			organizationIdList: payload.organizationIdList,
			remark: payload.remark,
		}
	});
}

function removeById(id) {
	return http({
		url: "/UmsUserPermission/RemoveById/" + id,
		method: "post",
	});
}

export default {
	listUmsOrganization,
	listByUserId,
	create,
	removeById
};

<template>
  <div class="hms-nucleic-acid">
    <div class="header">
      <a-breadcrumb style="margin-bottom: 20px">
        <a-breadcrumb-item>健康系统</a-breadcrumb-item>
        <a-breadcrumb-item>核检上传记录</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row>
        <a-col :span="18">
          <a-select
            style="width: 150px; margin-right: 10px"
            notFoundContent="无数据"
            placeholder="请选择组织架构"
            :options="
              umsOrganizationList.map((item) => ({
                value: item.id,
                label: item.name,
              }))
            "
            :value="umsOrganizationValue"
            @change="handleUmsOrganizationSelectOptionChange"
          />
          <a-range-picker
            @change="
              (date, dateString) => {
                this.queryDate = dateString;
              }
            "
          />
          <a-button
            type="primary"
            icon="search"
            style="margin-left: 10px"
            @click="handleQueryDateBtnClick"
          >查询
          </a-button
          >
        </a-col>
        <a-col :span="6">
          <a-input-search
            v-model="dataSourceSearchInput"
            placeholder="请输入任何想搜索的内容"
            allowClear
            @search="handleDataSourceSearch"
          />
        </a-col>
      </a-row>
    </div>
    <div class="body">
      <a-table
        rowKey="id"
        :data-source="getCurrentDataSourceList"
        :pagination="paginationOptions"
        @change="
          (pagination) => {
            paginationOptions.current = pagination.current;
            paginationOptions.pageSize = pagination.pageSize;
          }
        "
        :scroll="{ x: 1200 }"
        bordered
        size="small"
      >
        <a-table-column key="index" title="序号" :width="60" data-index="index" fixed="left">
          <template slot-scope="text, record, index">
            <span>{{
                (paginationOptions.current - 1) * paginationOptions.pageSize +
                parseInt(index) +
                1
              }}</span>
          </template>
        </a-table-column>
        <a-table-column
          key="checkDate"
          title="核检日期"
          :width="200"
          data-index="checkDate"
        />
        <a-table-column
          key="department"
          title="核检单位"
          :width="200"
          data-index="department"
        />
        <a-table-column
          key="type"
          title="核检方式"
          :width="100"
          data-index="type"
        />
        <a-table-column
          key="target"
          title="核检对象"
          :width="200"
          data-index="target"
        />
        <a-table-column title="核检图片" :width="100">
          <template slot-scope="text">
            <a @click="handlePhotosShowBtnClick(text)">查看详情</a>
          </template>
        </a-table-column>
        <a-table-column title="核检报告" :width="100">
          <template slot-scope="text">
            <a @click="handleReportsShowBtnClick(text)">查看详情</a>
          </template>
        </a-table-column>
        <a-table-column
          key="remark"
          title="备注"
          :width="200"
          data-index="remark"
        />
        <a-table-column
          key="createTime"
          title="创建时间"
          :width="200"
          data-index="createTime"
        />
        <a-table-column
          key="createByName"
          title="创建人"
          :width="200"
          data-index="createByName"
		  fixed="right"
        />
      </a-table>
    </div>
    <div class="footer"></div>

    <!-- 核检图片查看 -->
    <a-modal
      title="核检图片"
      :visible="photosModalVisible"
      :footer="null"
      @cancel="photosModalVisible = false"
      closable
    >
      <a-row v-if="photosUrlList.length > 0">
        <a-col :span="8" v-for="(item, index) in photosUrlList" :key="index">
          <a-card hoverable style="width: 100%">
            <img slot="cover" alt="example" :src="item" />
            <a-card-meta title="点击查看原图">
              <template slot="description">
                <a :href="item" target="_blank">{{ item }}</a>
              </template>
            </a-card-meta>
          </a-card>
        </a-col>
      </a-row>
      <div v-else>
        <a-empty />
      </div>
    </a-modal>

    <!-- 核检报告查看 -->
    <a-modal
      title="核检报告"
      :visible="reportsModalVisible"
      :footer="null"
      @cancel="reportsModalVisible = false"
      closable
    >
      <div v-if="reportsUrlList.length > 0">
        <div v-for="(item, index) in reportsUrlList" :key="index">
          <div>{{ item }} <a :href="item" target="_blank">查看</a></div>
        </div>
      </div>
      <div v-else>
        <a-empty />
      </div>
    </a-modal>
  </div>
</template>

<script>
import PaginationOptions from "@/options/pagination.js";
import FiltrationOptions from "@/options/filtration.js";
import HmsNucleicAcidApi from "@/api/hms/hms-nucleic-acid.js";
import UmsUserPermissionApi from "@/api/ums/ums-user-permission.js";
import { mapState } from "vuex";

export default {
  name: "HmsNucleicAcid",
  data() {
    return {
      paginationOptions: PaginationOptions,
      filtrationOptions: FiltrationOptions,
      photoModalVisible: false,
      queryType: "1",
      queryDate: [],
      dataSourceList: [],
      dataSourceCurrent: {},
      dataSourceSearchInput: "",
      dataSourceSearchList: [],
      dataSourceSearchValid: false,
      umsOrganizationValue: "",
      umsOrganizationList: [],
      photosUrlList: [],
      photosModalVisible: false,
      reportsUrlList: [],
      reportsModalVisible: false
    };
  },
  computed: {
    ...mapState({
      screenSpec: (state) => state.screenSpec
    }),
    getCurrentDataSourceList: function() {
      return this.dataSourceSearchValid
        ? this.dataSourceSearchList
        : this.dataSourceList;
    }
  },
  watch: {
    dataSourceSearchInput: function(val) {
      if (val === null || val === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
      }
    }
  },
  mounted: function() {
    this.fetchUmsOrganizationList();
  },
  methods: {
    fetchUmsOrganizationList: function() {
      UmsUserPermissionApi.listUmsOrganization("HmsNucleicAcid").then((response) => {
        if (response.status === "SUCCESS") {
          this.umsOrganizationList = response.body;
          if (this.umsOrganizationList.length > 0) this.umsOrganizationValue = this.umsOrganizationList[0].id;
        } else {
          this.umsOrganizationList = [];
        }
      });
    },
    fetchCheckinVisitorList: function() {
      HmsNucleicAcidApi.search(this.queryDate[0], this.queryDate[1], [this.umsOrganizationValue]).then((response) => {
        if (response.status === "SUCCESS") {
          this.dataSourceList = response.body;
          if (this.dataSourceList.length === 0) this.$message.success("无数据");
        } else {
          this.dataSourceList = [];
        }
      });
    },
    handleUmsOrganizationSelectOptionChange: function(value) {
      this.umsOrganizationValue = value;
    },
    handleQueryTypeSelectOptionChange: function(value) {
      const temp = ["createTime"];
      this.queryType = (temp.indexOf(value) + 1).toString();
    },
    handleQueryDateBtnClick: function() {
      if (!this.queryDate || this.queryDate.length === 0) {
        this.$message.error("请先选择日期后再查询！");
        return;
      }
      this.fetchCheckinVisitorList();
    },
    handleTableChange(pagination) {
      this.paginationOptions.current = pagination.current;
      this.paginationOptions.pageSize = pagination.pageSize;
    },
    handleDataSourceSearch: function(value) {
      if (value === null || value === "") {
        this.dataSourceSearchList = [];
        this.dataSourceSearchValid = false;
        return;
      }
      this.dataSourceSearchList = this.dataSourceList.filter((item) => {
        return JSON.stringify(item).indexOf(value.trim()) !== -1;
      });
      this.dataSourceSearchValid = true;
    },
    handlePhotosShowBtnClick: function(payload) {
      this.photosUrlList = [];
      const temp = JSON.parse(payload.photos);
      temp.forEach((item) => {
        if (item.url) this.photosUrlList.push(item.url);
      });
      this.photosModalVisible = true;
    },
    handleReportsShowBtnClick: function(payload) {
      this.reportsUrlList = [];
      const temp = JSON.parse(payload.reports);
      temp.forEach((item) => {
        if (item.url) this.reportsUrlList.push(item.url);
      });
      this.reportsModalVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 20px;
}
</style>

import http from "@/api/http.js";

function search(startTime, endTime, umsOrganizationIdList) {
  return http({
    url: "/HmsNucleicAcid/Search",
    method: "post",
    data: {
      startTime: startTime,
      endTime: endTime,
      umsOrganizationIdList: umsOrganizationIdList,
      sysAppMenuName: "HmsNucleicAcid"
    }
  });
}

export default {
  search
};
